import * as THREE from 'three'
import Project from './Project'
import gsap from 'gsap/all'

// mad-projects
import projectsAirliftSlideASources from '../../../models/mad-projects/Airlift/slideA.png'
import projectsAirliftSlideBSources from '../../../models/mad-projects/Airlift/slideB.png'
import projectsAirliftSlideCSources from '../../../models/mad-projects/Airlift/slideC.png'

import projectsBabelcubeSlideASources from '../../../models/mad-projects/Babelcube/slideA.png'
import projectsBabelcubeSlideBSources from '../../../models/mad-projects/Babelcube/slideB.png'
import projectsBabelcubeSlideCSources from '../../../models/mad-projects/Babelcube/slideC.png'

import projectsCricketCardBattleSlideASources from '../../../models/mad-projects/CricketCardBattle/slideA.png'
import projectsCricketCardBattleSlideBSources from '../../../models/mad-projects/CricketCardBattle/slideB.png'
import projectsCricketCardBattleSlideCSources from '../../../models/mad-projects/CricketCardBattle/slideC.png'

import projectsCupcakeDreamlandSlideASources from '../../../models/mad-projects/CupcakeDreamland/slideA.png'
import projectsCupcakeDreamlandSlideBSources from '../../../models/mad-projects/CupcakeDreamland/slideB.png'
import projectsCupcakeDreamlandSlideCSources from '../../../models/mad-projects/CupcakeDreamland/slideC.png'

import projectsDialysisManagementSlideASources from '../../../models/mad-projects/DialysisManagement/slideA.png'
import projectsDialysisManagementSlideBSources from '../../../models/mad-projects/DialysisManagement/slideB.png'
import projectsDialysisManagementSlideCSources from '../../../models/mad-projects/DialysisManagement/slideC.png'

import projectsEnjoyPyramidsSlideASources from '../../../models/mad-projects/EnjoyPyramids/slideA.png'
import projectsEnjoyPyramidsSlideBSources from '../../../models/mad-projects/EnjoyPyramids/slideB.png'
import projectsEnjoyPyramidsSlideCSources from '../../../models/mad-projects/EnjoyPyramids/slideC.png'

import projectsFlipsideWorkspaceSlideASources from '../../../models/mad-projects/FlipsideWorkspace/slideA.png'
import projectsFlipsideWorkspaceSlideBSources from '../../../models/mad-projects/FlipsideWorkspace/slideB.png'
import projectsFlipsideWorkspaceSlideCSources from '../../../models/mad-projects/FlipsideWorkspace/slideC.png'

import projectsFlyingJattSlideASources from '../../../models/mad-projects/FlyingJatt/slideA.png'
import projectsFlyingJattSlideBSources from '../../../models/mad-projects/FlyingJatt/slideB.png'
import projectsFlyingJattSlideCSources from '../../../models/mad-projects/FlyingJatt/slideC.png'

//GrandmastersofInfluence
import projectsGOISlideASources from '../../../models/mad-projects/GOI/slideA.png'
import projectsGOISlideBSources from '../../../models/mad-projects/GOI/slideB.png'
import projectsGOISlideCSources from '../../../models/mad-projects/GOI/slideC.png'

import projectsHeathcliffFastFurriestSlideASources from '../../../models/mad-projects/HeathcliffFastFurriest/slideA.png'
import projectsHeathcliffFastFurriestSlideBSources from '../../../models/mad-projects/HeathcliffFastFurriest/slideB.png'
import projectsHeathcliffFastFurriestSlideCSources from '../../../models/mad-projects/HeathcliffFastFurriest/slideC.png'
import projectsHeathcliffFastFurriestSlideDSources from '../../../models/mad-projects/HeathcliffFastFurriest/slideD.png'


import projectsICCProCricket2015SlideASources from '../../../models/mad-projects/ICCProCricket2015/slideA.png'
import projectsICCProCricket2015SlideBSources from '../../../models/mad-projects/ICCProCricket2015/slideB.png'
import projectsICCProCricket2015SlideCSources from '../../../models/mad-projects/ICCProCricket2015/slideC.png'

import projectsMassCasualtyScenarioSlideASources from '../../../models/mad-projects/MassCasualtyScenario/slideA.png'
import projectsMassCasualtyScenarioSlideBSources from '../../../models/mad-projects/MassCasualtyScenario/slideB.png'
import projectsMassCasualtyScenarioSlideCSources from '../../../models/mad-projects/MassCasualtyScenario/slideC.png'

import projectsNurseTrainingSlideASources from '../../../models/mad-projects/NurseTraining/slideA.png'
import projectsNurseTrainingSlideBSources from '../../../models/mad-projects/NurseTraining/slideB.png'
import projectsNurseTrainingSlideCSources from '../../../models/mad-projects/NurseTraining/slideC.png'

import projectsVirtualPatientManagementSlideASources from '../../../models/mad-projects/VirtualPatientManagement/slideA.png'
import projectsVirtualPatientManagementSlideBSources from '../../../models/mad-projects/VirtualPatientManagement/slideB.png'
import projectsVirtualPatientManagementSlideCSources from '../../../models/mad-projects/VirtualPatientManagement/slideC.png'

export default class ProjectsSection
{
    constructor(_options)
    {
        // Options
        this.time = _options.time
        this.resources = _options.resources
        this.camera = _options.camera
        this.passes = _options.passes
        this.objects = _options.objects
        this.areas = _options.areas
        this.zones = _options.zones
        this.tiles = _options.tiles
        this.debug = _options.debug
        this.x = _options.x
        this.y = _options.y

        // Debug
        if(this.debug)
        {
            this.debugFolder = this.debug.addFolder('projects')
            this.debugFolder.open()
        }

        // Set up
        this.items = []

        this.interDistance = 24
        this.positionRandomess = 5
        this.projectHalfWidth = 9

        this.container = new THREE.Object3D()
        this.container.matrixAutoUpdate = false
        this.container.updateMatrix()

        this.setGeometries()
        this.setMeshes()
        this.setList()
        this.setZone()

        // Add all project from the list
        for(const _options of this.list)
        {
            this.add(_options)
        }
    }

    setGeometries()
    {
        this.geometries = {}
        this.geometries.floor = new THREE.PlaneBufferGeometry(16, 8)
    }

    setMeshes()
    {
        this.meshes = {}

        // this.meshes.boardStructure = this.objects.getConvertedMesh(this.resources.items.projectsBoardStructure.scene.children, { floorShadowTexture: this.resources.items.projectsBoardStructureFloorShadowTexture })
        this.resources.items.areaOpenTexture.magFilter = THREE.NearestFilter
        this.resources.items.areaOpenTexture.minFilter = THREE.LinearFilter
        this.meshes.boardPlane = this.resources.items.projectsBoardPlane.scene.children[0]
        this.meshes.areaLabel = new THREE.Mesh(new THREE.PlaneBufferGeometry(2, 0.5), new THREE.MeshBasicMaterial({ transparent: true, depthWrite: false, color: 0xffffff, alphaMap: this.resources.items.areaOpenTexture }))
        this.meshes.areaLabel.matrixAutoUpdate = false
    }

    setList()
    {
        this.list = [
            {
                name: 'Airlift',
                imageSources:
                [
                    projectsAirliftSlideASources,
                    projectsAirliftSlideBSources,
                    projectsAirliftSlideCSources
                ],
                floorTexture: this.resources.items.projectsAirliftFloorTexture,
                link:
                {
                    href: 'https://apptopia.com/unified/app/8182714516/about',
                    x: - 4,
                    y: - 5,
                    halfExtents:
                    {
                        x: 4,
                        y: 1.5
                    }
                },
                distinctions:
                [
                ]
            },
            {
                name: 'Flying jatt',
                imageSources:
                [
                    projectsFlyingJattSlideASources,
                    projectsFlyingJattSlideBSources,
                    projectsFlyingJattSlideCSources
                ],
                floorTexture: this.resources.items.projectsFlyingJattFloorTexture,
                link:
                {
                    href: 'https://apptopia.com/unified/app/8100992202/about',
                    x: - 4,
                    y: - 5,
                    halfExtents:
                    {
                        x: 4,
                        y: 1.5
                    }
                },
                distinctions:
                [
                ]
            },
            {
                name: 'ICC Pro Cricket 2015',
                imageSources:
                [
                    projectsICCProCricket2015SlideASources,
                    projectsICCProCricket2015SlideBSources,
                    projectsICCProCricket2015SlideCSources
                ],
                floorTexture: this.resources.items.projectsICCProCricket2015FloorTexture,
                link:
                {
                    href: 'https://apptopia.com/google-play/app/com.indiagames.procricket_android/about',
                    x: - 4,
                    y: - 5.0,
                    halfExtents:
                    {
                        x: 4,
                        y: 1.5
                    }
                },
                distinctions:
                [
                ]
            },
            {
                name: 'Cupcake Dreamland',
                imageSources:
                [
                    projectsCupcakeDreamlandSlideASources,
                    projectsCupcakeDreamlandSlideBSources,
                    projectsCupcakeDreamlandSlideCSources
                ],
                floorTexture: this.resources.items.projectsCupcakeDreamlandFloorTexture,
                link:
                {
                    href: 'https://apptopia.com/unified/app/8080591942/about',
                    x: - 4,
                    y: - 5,
                    halfExtents:
                    {
                        x: 4,
                        y: 1.5
                    }
                },
                distinctions:
                [
                ]
            },
            {
                name: 'Cricket Card Battle',
                imageSources:
                [
                    projectsCricketCardBattleSlideASources,
                    projectsCricketCardBattleSlideBSources,
                    projectsCricketCardBattleSlideCSources
                ],
                floorTexture: this.resources.items.projectsCricketCardBattleFloorTexture,
                link:
                {
                    href: 'https://apptopia.com/unified/app/8081208106/about',
                    x: - 4,
                    y: - 5,
                    halfExtents:
                    {
                        x: 4,
                        y: 1.5
                    }
                },
                distinctions:
                [
                ]
            },
            {
                name: 'Heathcliff Fast and Furriest',
                imageSources:
                [
                    projectsHeathcliffFastFurriestSlideASources,
                    projectsHeathcliffFastFurriestSlideBSources,
                    projectsHeathcliffFastFurriestSlideCSources,
                    projectsHeathcliffFastFurriestSlideDSources
                ],
                floorTexture: this.resources.items.projectsHeathcliffFastFurriestFloorTexture,
                link:
                {
                    href: 'https://www.amazon.com/Heathcliff-Fast-Furriest-Nintendo-Wii/dp/B003OQ2ZPU',
                    x: - 4,
                    y: - 5,
                    halfExtents:
                    {
                        x: 4,
                        y: 1.5
                    }
                },
                distinctions:
                [
                ]
            },
            {
                name: 'Babelcube',
                imageSources:
                [
                    projectsBabelcubeSlideASources,
                    projectsBabelcubeSlideBSources,
                    projectsBabelcubeSlideCSources
                ],
                floorTexture: this.resources.items.projectsBabelcubeFloorTexture,
                link:
                {
                    href: 'http://blackcobrastudios.com/games/babelcube',
                    x: - 4,
                    y: - 5,
                    halfExtents:
                    {
                        x: 4,
                        y: 1.5
                    }
                },
                distinctions:
                [
                ]
            },
            {
                name: 'Enjoy Pyramids',
                imageSources:
                [
                    projectsEnjoyPyramidsSlideASources,
                    projectsEnjoyPyramidsSlideBSources,
                    projectsEnjoyPyramidsSlideCSources
                ],
                floorTexture: this.resources.items.projectsEnjoyPyramidsFloorTexture,
                link:
                {
                    href: 'http://blackcobrastudios.com/games/enjoypryamids',
                    x: - 4,
                    y: - 5,
                    halfExtents:
                    {
                        x: 4,
                        y: 1.5
                    }
                },
                distinctions:
                [
                ]
            },
            {
                name: 'Virtual Patient Management',
                imageSources:
                [
                    projectsVirtualPatientManagementSlideASources,
                    projectsVirtualPatientManagementSlideBSources,
                    projectsVirtualPatientManagementSlideCSources
                ],
                floorTexture: this.resources.items.projectsVirtualPatientManagementFloorTexture,
                link:
                {
                    href: 'https://www.indusgeeks.com/project-virtual-patient-management.php',
                    x: - 4,
                    y: - 5,
                    halfExtents:
                    {
                        x: 4,
                        y: 1.5
                    }
                },
                distinctions:
                [
                ]
            },
            {
                name: 'Nurse Training',
                imageSources:
                [
                    projectsNurseTrainingSlideASources,
                    projectsNurseTrainingSlideBSources,
                    projectsNurseTrainingSlideCSources
                ],
                floorTexture: this.resources.items.projectsNurseTrainingFloorTexture,
                link:
                {
                    href: 'https://www.indusgeeks.com/project-nurse-training.php',
                    x: -4,
                    y: - 5,
                    halfExtents:
                    {
                        x: 4,
                        y: 1.5
                    }
                },
                distinctions: []
            },
            {
                name: 'Mass Casualty Scenario',
                imageSources:
                [
                    projectsMassCasualtyScenarioSlideASources,
                    projectsMassCasualtyScenarioSlideBSources,
                    projectsMassCasualtyScenarioSlideCSources
                ],
                floorTexture: this.resources.items.projectsMassCasualtyScenarioFloorTexture,
                link:
                {
                    href: 'https://www.indusgeeks.com/project-mass-casualty-scenario.php',
                    x: -4,
                    y: - 5,
                    halfExtents:
                    {
                        x: 4,
                        y: 1.5
                    }
                },
                distinctions: []
            },
            {
                name: 'Dialysis Management',
                imageSources:
                [
                    projectsDialysisManagementSlideASources,
                    projectsDialysisManagementSlideBSources,
                    projectsDialysisManagementSlideCSources
                ],
                floorTexture: this.resources.items.projectsDialysisManagementFloorTexture,
                link:
                {
                    href: 'https://www.indusgeeks.com/project-dialysis-management.php',
                    x: -4,
                    y: - 5,
                    halfExtents:
                    {
                        x: 4,
                        y: 1.5
                    }
                },
                distinctions: []
            },
            {
                name: 'Flipside Workspace',
                imageSources:
                [
                    projectsFlipsideWorkspaceSlideASources,
                    projectsFlipsideWorkspaceSlideBSources,
                    projectsFlipsideWorkspaceSlideCSources
                ],
                floorTexture: this.resources.items.projectsFlipsideWorkspaceFloorTexture,
                link:
                {
                    href: 'https://www.indusgeeks.com/project-flipside-workspace.php',
                    x: -4,
                    y: - 6,
                    halfExtents:
                    {
                        x: 4,
                        y: 1.5
                    }
                },
                distinctions: []
            },
            {
                name: 'Grandmasters of Influence',
                imageSources:
                [
                    projectsGOISlideASources,
                    projectsGOISlideBSources,
                    projectsGOISlideCSources
                ],
                floorTexture: this.resources.items.projectsGOIFloorTexture,
                link:
                {
                    href: 'https://www.indusgeeks.com/project-grandmasters-of-influence.php',
                    x: -4,
                    y: - 5,
                    halfExtents:
                    {
                        x: 4,
                        y: 1.5
                    }
                },
                distinctions: []
            }

        ]
    }

    setZone()
    {
        const totalWidth = this.list.length * (this.interDistance / 2)

        const zone = this.zones.add({
            position: { x: this.x + totalWidth - this.projectHalfWidth - 6, y: this.y },
            halfExtents: { x: totalWidth, y: 12 },
            data: { cameraAngle: 'projects' }
        })

        zone.on('in', (_data) =>
        {
            this.camera.angle.set(_data.cameraAngle)
            gsap.to(this.passes.horizontalBlurPass.material.uniforms.uStrength.value, 2, { x: 0 })
            gsap.to(this.passes.verticalBlurPass.material.uniforms.uStrength.value, 2, { y: 0 })
        })

        zone.on('out', () =>
        {
            this.camera.angle.set('default')
            gsap.to(this.passes.horizontalBlurPass.material.uniforms.uStrength.value, 2, { x: this.passes.horizontalBlurPass.strength })
            gsap.to(this.passes.verticalBlurPass.material.uniforms.uStrength.value, 2, { y: this.passes.verticalBlurPass.strength })
        })
    }

    add(_options)
    {
        const x = this.x + this.items.length * this.interDistance
        let y = this.y
        if(this.items.length > 0)
        {
            y += (Math.random() - 0.5) * this.positionRandomess
        }

        // Create project
        const project = new Project({
            time: this.time,
            resources: this.resources,
            objects: this.objects,
            areas: this.areas,
            geometries: this.geometries,
            meshes: this.meshes,
            debug: this.debugFolder,
            x: x,
            y: y,
            ..._options
        })

        this.container.add(project.container)

        // Add tiles
        if(this.items.length >= 1)
        {
            const previousProject = this.items[this.items.length - 1]
            const start = new THREE.Vector2(previousProject.x + this.projectHalfWidth, previousProject.y)
            const end = new THREE.Vector2(project.x - this.projectHalfWidth, project.y)
            const delta = end.clone().sub(start)
            this.tiles.add({
                start: start,
                delta: delta
            })
        }

        // Save
        this.items.push(project)
    }
}
